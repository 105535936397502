import React from "react"
import Helmet from "react-helmet"
import PrivacyPolicy from "@tightrope/footerlinks/privacy-policy"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"

export default function Privacy() {
  return(

    <HomepageLayout>
        <Helmet data={JSONData}><title>Privacy Policy - breakingnewstab.com</title></Helmet>
    <PrivacyPolicy>    <Helmet>Test</Helmet></PrivacyPolicy>
    </HomepageLayout>
  )
}
